<template>
   <form @submit.prevent="submitForm" class="callback-form form" action="#" novalidate>
      <app-field
         v-for="field in fields"
         :key="field.name"
         :is-form-submission="isFormSubmission"
         :is-displayed-error="isDisplayedError"
         :settings="field"
         @result-checking="saveResultChecking"
         @form-reset="resetForm"
      />

      <button class="callback-form__btn btn">Отправить</button>
   </form>
</template>

<script>
   import AppField from '@/components/templates/FormFields/AppField';

   export default {
      name: 'ModalCallBack',

      components: {
         AppField
      },

      emits: {
         'update:title': (value) => typeof value === 'string'
      },

      inject: ['openModal'],

      data() {
         return {
            isFormSubmission: false,
            isDisplayedError: false,
            fieldsValidationResults: [],

            fields: [
               {
                  name: 'name',
                  type: 'text',
                  validationType: 'Name',
                  placeholder: 'Имя',
                  params: { minLength: 2, maxLength: 20 }
               },
               {
                  name: 'phone',
                  type: 'tel',
                  validationType: 'Phone',
                  placeholder: 'Номер телефона'
               }
            ],

            modalSettings: {
               name: 'ModalNotification',
               iconName: 'ready',
               message: 'Спасибо за вашу заявку!',
               isActive: true
            }
         };
      },

      beforeMount() {
         this.$emit('update:title', 'Перезвоните мне');
      },

      methods: {
         saveResultChecking(value) {
            const field = this.fieldsValidationResults.find(
               (item) => item.name === value.name && item.type === value.type
            );

            if (field) {
               field.isValid = value.isValid;
            } else {
               this.fieldsValidationResults.push(value);
            }
         },

         resetForm(value) {
            this.isFormSubmission = value;
         },

         submitForm() {
            if (this.isValidFieldValue) {
               this.isFormSubmission = true;
               this.isDisplayedError = false;

               setTimeout(() => {
                  this.openModal(this.modalSettings);
               }, 200);
            } else {
               this.isDisplayedError = true;
            }
         }
      },

      computed: {
         isValidFieldValue() {
            return (
               this.fieldsValidationResults.length &&
               this.fieldsValidationResults.every((item) => item.isValid)
            );
         }
      }
   };
</script>
