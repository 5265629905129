'use strict';

export const createPhoneFieldTemplate = (field, masked = '+7 (___) ___-__-__') => {
   const template = masked,
      def = template.replace(/\D/g, ''),
      value = field.replace(/\D/g, '');

   let i = 0,
      newValue = template.replace(/[_\d]/g, function (a) {
         return i < value.length ? value.charAt(i++) || def.charAt(i) : a;
      });

   i = newValue.indexOf('_');

   if (i != -1) {
      newValue = newValue.slice(0, i);
   }

   let reg = template
      .substring(0, field.length)
      .replace(/_+/g, function (a) {
         return '\\d{1,' + a.length + '}';
      })
      .replace(/[+()]/g, '\\$&');

   reg = new RegExp('^' + reg + '$');

   if (!reg.test(field) || field.length < 5) {
      return newValue;
   }

   return field;
};
