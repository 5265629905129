<template>
   <div class="input-wrap">
      <input
         :type="settings.type"
         :name="settings.name"
         :placeholder="settings.placeholder"
         ref="field"
         v-model.trim="field"
         @input="sendFieldContent"
         @focus="addPhoneFieldTemplate"
         @blur="clearPhoneFieldValue"
         class="input"
      />

      <p v-if="resultOfChecking.isError && isDisplayedError" class="error">
         {{ resultOfChecking.errorMessage }}
      </p>
   </div>
</template>

<script>
   import { validateEmailField } from './utils/validation/email';
   import { validateMessageField } from './utils/validation/message';
   import { validateNameField } from './utils/validation/name';
   import { validatePasswordField } from './utils/validation/password';
   import { validatePassConfirmationField } from './utils/validation/passConfirmation';
   import { validatePhoneField } from './utils/validation/phone';
   import { createPhoneFieldTemplate } from './utils/templates/phoneFieldTemplate';

   export default {
      name: 'AppField',

      props: {
         settings: { type: Object, required: true },
         isDisplayedError: { type: Boolean, required: true },
         isFormSubmission: { type: Boolean, required: true }
      },

      emits: {
         'result-checking': (value) => typeof value === 'object',
         'form-reset': (value) => typeof value === 'boolean',
         'update:field': (value) => typeof value === 'string'
      },

      data() {
         return {
            field: '',
            resultOfChecking: { isError: false, errorMessage: '' }
         };
      },

      methods: {
         sendFieldContent() {
            this.addPhoneFieldTemplate();
            this.$emit('update:field', this.field);
         },

         addPhoneFieldTemplate() {
            if (this.settings.name === 'phone') {
               this.field = createPhoneFieldTemplate(this.field);
            }
         },

         clearPhoneFieldValue() {
            if (this.settings.name === 'phone') {
               this.field = this.field.length < 5 ? '' : this.field;
            }
         },

         validateEmailField(value) {
            return validateEmailField(value);
         },

         validateMessageField(value) {
            return validateMessageField(value, this.settings.params);
         },

         validateNameField(value) {
            return validateNameField(value, this.settings.params);
         },

         validatePasswordField(value) {
            return validatePasswordField(value, this.settings.params);
         },

         validatePassConfirmationField(value) {
            return validatePassConfirmationField(value, this.settings.data);
         },

         validatePhoneField(value) {
            return validatePhoneField(value);
         }
      },

      computed: {
         isFormSent() {
            return (
               !this.resultOfChecking.isError && !this.isDisplayedError && this.isFormSubmission
            );
         }
      },

      watch: {
         isFormSent(value) {
            if (value) {
               this.field = '';
               this.resultOfChecking = { isError: false, errorMessage: '' };
               this.$refs.field.blur();

               this.$emit('form-reset', false); //this.isFormSubmission - false
            }
         },

         field: {
            handler(value) {
               const { name, type, validationType } = this.settings;

               this.resultOfChecking = this[`validate${validationType}Field`](value);

               this.$emit('result-checking', {
                  name,
                  type,
                  validationType,
                  isValid: !this.resultOfChecking.isError
               });
            },
            immediate: true
         }
      }
   };
</script>
