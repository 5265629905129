'use strict';

export const validateNameField = (value, settings) => {
   if (!settings || (!settings.minLength && !settings.maxLength)) {
      return { isError: false, errorMessage: '' };
   }

   const { minLength, maxLength } = settings;
   const errorMessage =
      value.length < minLength && minLength
         ? `Минимальное число символов: ${minLength}`
         : `Максимальное число символов: ${maxLength}`;

   return (value.length < minLength && minLength) || (value.length > maxLength && maxLength)
      ? { isError: true, errorMessage }
      : { isError: false, errorMessage: '' };
};
