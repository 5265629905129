'use strict';

export const validatePasswordField = (value, settings) => {
   const TEMPLATE = /^[A-Za-z0-9-$#]+$/g;
   const { minLength, maxLength } = settings;
   const errorMessage =
      value.length < minLength && minLength
         ? `Минимальное число символов: ${minLength}`
         : `Максимальное число символов: ${maxLength}`;

   return !TEMPLATE.test(value)
      ? { isError: true, errorMessage: 'Неверный ввод!' }
      : (value.length < minLength && minLength) || (value.length > maxLength && maxLength)
      ? { isError: true, errorMessage }
      : { isError: false, errorMessage: '' };
};
