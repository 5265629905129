'use strict';

export const validateEmailField = (value) => {
   const TEMPLATE = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

   const errorMessage = !value ? 'Пустое поле!' : 'Неверный ввод!';

   return !value || !TEMPLATE.test(value)
      ? { isError: true, errorMessage }
      : { isError: false, errorMessage: '' };
};
